import React from "react"

import { useTranslation } from "react-i18next"

import "./cookie-table.css"

const CookieTable = () => {
  const { t } = useTranslation()

  const cookiesData = t("cookie-table-data", { returnObjects: true })

  return (
    <div className="cookie-table">
      <div className="cookie-table-wrapper table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("cookie-table-column-name")}</th>
              <th scope="col">{t("cookie-table-column-category")}</th>
              <th scope="col">{t("cookie-table-column-type")}</th>
              <th scope="col">{t("cookie-table-column-source")}</th>
              <th scope="col">{t("cookie-table-column-time")}</th>
              <th scope="col">{t("cookie-table-column-desc")}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(cookiesData) &&
              cookiesData.map(({ name, category, type, source, time, desc }) => (
                <tr key={name}>
                  <th scope="row">{name}</th>
                  <td>{category}</td>
                  <td>{type}</td>
                  <td>{source}</td>
                  <td>{time}</td>
                  <td>{desc}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CookieTable

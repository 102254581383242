import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = props => {
  return (
    <div className="breadcrumbs">
      <div className="container">
        <ul>
          {props.path.map((elem, index) => (
            <li key={index}>
              {index < props.path.length - 1 ? (
                <>
                  <Link to={elem.url}>
                    <span>{elem.text}</span>
                  </Link>{" "}
                  <span className="separator">/</span>
                  <meta content={index + 1} />
                </>
              ) : (
                <>
                  <span className="last-element">{elem.text}</span>
                  <meta content={index + 1} />
                  <meta href={elem.url} />
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Breadcrumbs

import React, { useEffect } from "react"

import Layout from "../../components/Layout/Layout"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import CookieTable from "../../components/CookieTable/CookieTable"

import HeaderImage from "../../assets/img/new/tlo-serch-01.webp"

import "../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const CookiesPolicy = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("en")
  }, [])
  return (
    <Layout
      seo={{
        title: t("cookie-table-header"),
        description: t("cookie-table-header"),
        robots: "noindex, nofollow",
        hreflangs: {
          pl: "polityka-cookies/tabela-cookies",
          en: "en/cookies-policy/cookies-table",
          de: "de/cookies-politik/cookie-tisch",
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-cookies-table"),
                text: t("link-text-cookies-table"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>{t("cookie-table-header")}</h1>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <CookieTable />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CookiesPolicy
